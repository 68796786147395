import React from "react";
import eric from "../img/eric.jpg"
import kieran from "../img/kieran.jpg"
import omar from "../img/omar.jpg"
import bg from "../img/codebg.jpg"
import christian from "../img/christian.jpg"

export const Whoweare = (props) => {
  return (
    <div id="whoweare" style={{backgroundImage: `url(${bg})`}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="whoweare-text">
              <h2>{props.data ? props.data.title : "loading..."}</h2>
              <h3>{props.data ? props.data.paragraph1 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph2 : "loading..."}</h3>
            </div>
          </div>
        </div>
        <div id="row">
          <div className="col-md-3 col-sm-6 team">
            <div className="thumbnail">
              <img src={kieran} alt="..." className="team-img"/>
              <div className="caption">
                <h3>Kieran Parker</h3>
                <h4>Co-founder</h4>
                <p>Also Research Investment Manager, Australian Centre for Field Robotics, University of Sydney</p>
                <p>Former PhD Candidate (Stanford, Cambridge)</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 team">
            <div className="thumbnail">
              <img src={eric} alt="..." className="team-img"/>
              <div className="caption">
                <h3>Eric Chan</h3>
                <h4>Co-founder</h4>
                <p>Also Director Finance, University of Sydney</p>
                <p>Executive MBA Candidate (USYD)</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 team">
            <div className="thumbnail">
              <img src={omar} alt="..." className="team-img"/>
              <div className="caption">
                <h3>Omar Husain</h3>
                <h4>Co-founder</h4>
                <p>Previously Technical Program Manager, Amazon</p>
                <p>MBA (USYD), Bachelors of Computing (UTAS)</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 team">
            <div className="thumbnail">
              <img src={christian} alt="..." className="team-img"/>
              <div className="caption">
                <h3>Christian Fane</h3>
                <h4>Co-founder</h4>
                <p>Currently studying Bachelors of Advanced Computing (USYD)</p>
              </div>
            </div>
          </div>
          {/* {props.data
            ? props.data.team.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h3>{d.name}</h3>
                      <h4>{d.job}</h4>
                      <p>{d.also1}</p>
                      <p>{d.also2}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
