import React from "react";

export const Footer = (props) => {
    return (
        <div id="footer">
            <div className="container">
                <div className="col-md-12">
                    <div className="row">
                        <div className="social">
                            <ul>
                                <li className="website">
                                    <a href={props.data ? props.data.website : "/"}>
                                        {props.data ? props.data.website : "loading..."}
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.twitter : "/"}>
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.linkedin : "/"}>
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                                <li className="email"> 
                                    <a href={props.data ? "mailto:" + props.data.email : "/"} rel="noreferrer" target="_blank">
                                        {props.data ? props.data.email : "loading..."}
                                    </a>
                                </li>
                                <li className="emailBtn"> 
                                    <a href={props.data ? "mailto:" + props.data.email : "/"} rel="noreferrer" target="_blank">
                                        <i className="fa fa-Envelope"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}